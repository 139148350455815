import { FC, useEffect, useState } from 'react';
import { SuperModal } from './SuperModal';
import { now } from 'lodash';

export const SuperNotice = () => {
  const [modalopen, setModalopen] = useState(false);

  useEffect(() => {
    const opendate = localStorage.getItem('showSuNotice');

    if (opendate) {
      const nowdate = new Date();
      if (nowdate.getTime().toString() > opendate) {
        setModalopen(true);
      } else {
        setModalopen(false);
      }
    } else {
      setModalopen(true);
    }
  }, []);

  const handlePopupClose = (period: number) => {
    const currentDate = new Date();

    const hidePopupUntil = new Date(
      currentDate.getTime() + period * 24 * 60 * 60 * 1000,
    );

    localStorage.setItem(
      'showSuNotice',
      (hidePopupUntil.getTime() + period).toString(),
    );

    setModalopen(false);
  };

  return (
    <div className="flex w-full items-center cursor-pointer">
      <SuperModal
        width="w-120"
        modalOpen={modalopen}
        setModalClose={() => handlePopupClose(1)}
      >
        <div className="text-center font-bold text-brand-1 mt-5 text-lg">
          슈퍼스쿨 소식
        </div>
        <div className="text-sm ml-6 mr-6 mb-6 mt-6">
          더 나은 서비스 제공을 위해 슈퍼스쿨을 새단장 합니다.
          <br />
          아래 기간동안 슈퍼스쿨 접속이 원활하지 않을 수 있습니다.
        </div>
        <div className=" font-bold ml-6 mr-6 mb-6 mt-6">
          기간 : 7월 22일 00시 ~ 8월 14일 00시
        </div>
        <div className="text-sm ml-3 mr-3 mb-6 mt-6">
          * 기간은 상황에 따라 달라질 수 있으며 2학기 개학 전에 정식 오픈합니다.
        </div>

        <div className="w-full flex items-center justify-center my-2 mb-5">
          <button
            className="bg-gray-100 border border-gray-100 text-littleblack w-4/5 rounded-lg py-2 font-bold"
            onClick={() => handlePopupClose(7)}
          >
            7일간 보지 않기
          </button>
        </div>
      </SuperModal>
    </div>
  );
};
